.popup__input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 400;
  font-size: 14px;
  color: #000;
  padding: 0 0 9px 0;
  margin-bottom: 31px;
  width: 100%;
}

.popup__input:last-of-type {
  margin-bottom: 0;
}

.popup__input:focus {
  outline: none;
}



.profile__add-button {
  width: 150px;
  height: 50px;
  border: 2px solid #fff;
  padding: 0;
  color: #fff;
  background-color: transparent;
  border-radius: 2px;
  margin-top: 2px;
  transition: 0.3s;
  background-image: url(./../../../images/icon-add-content.svg);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
}

.profile__add-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.profile__add-button:focus {
  outline: none;
}

.profile__add-button:first-of-type {
  font-size: 44px;
  font-family: none;
}

@media screen and (max-width: 935px) {
  .profile__add-button {
    margin-right: 19px;
  }
}

@media screen and (max-width: 742px) {
  .profile__add-button {
    margin: 0 auto;
    width: calc(100% - 38px);
  }
  .profile__add-button:first-of-type {
    font-size: 32px;
  }
}

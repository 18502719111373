.header {
  max-width: 100%;
  width: 880px;
  padding: 45px 0 0 0;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 742px) {
  .header {
    padding-top: 28px;
  }
}

.popup__box-success-title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 430px;
  height: 330px;
  text-align: center;
}

.popup__box-success-title > p {
  margin: -30px 36px 0;
}

.footer {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
}

@media screen and (max-width: 742px) {
  .footer {
    padding-bottom: 36px;
  }
}

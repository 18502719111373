.element__title {
  max-width: 190px;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.21;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  -o-text-overflow: ellipsis;
}

.element__button-delete {
  position: absolute;
  display: none;
  width: 18px;
  height: 19.3px;
  top: 18px;
  right: 15px;
  background-color: transparent;
  background-image: url(./../../../images/button-delete.svg);
  border: none;
  transition: 0.35s;
}

.element__button-delete:hover {
  cursor: pointer;
  background-image: url(./../../../images/button-delete-hover.svg);
}

.element__button-delete:focus {
  outline: none;
}

.header__logo {
  background-size: 142px 33px;
  width: 142px;
  height: 33px;
  background-repeat: no-repeat;
  margin-bottom: 41px;
}

@media screen and (max-width: 935px) {
  .header__logo {
    margin-left: 19px;
  }
}

@media screen and (max-width: 742px) {
  .header__logo {
    background-size: 103.74px 24.4px;
    width: 103.74px;
    height: 24.4px;
    margin-bottom: 31.6px;
    margin-left: 19px;
  }
}

.login__button {
  width: 100%;
  height: 50px;
  margin-top: 215px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.21;
  cursor: pointer;
  background-color: #fff;
  border: none;
  transition: 0.35s;
}

.login__button:hover {
  font-size: 20px;
}

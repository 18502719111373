.profile__photo {
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: 0.35s;
  position: relative;
  z-index: 1;
}

.profile__photo:hover>.profile__icon-edit {
  transition: 0.3s;
  opacity: 1;
}
.element__button-like {
  width: 22px;
  height: 19px;
  border: none;
  background-image: url(./../../../images/button-like-inactive.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  transition: 0.35s;
}

.element__button-like:hover {
  opacity: 0.5;
  cursor: pointer;
}

.element__button-like:focus {
  opacity: 0.7;
  cursor: pointer;
  outline: none;
}

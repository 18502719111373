.pagenotfound__link {
  text-decoration: none;
  transition: 0.25s;
  
}

.pagenotfound__link:link {
    color: #ffc83d;
}

.pagenotfound__link:visited {
    color: #ffc83d;
}

.pagenotfound__link:hover {
  color: #fff;
}

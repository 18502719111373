.header__log-reg-out {
  font-size: 18px;
  line-height: 1.22;
  font-weight: 400;
  margin: 0;
  padding-top: 8px;
  transition: 0.25s;
}

.header__log-reg-out:link{
    color: #ccc;
    text-decoration: none;
}

.header__log-reg-out:visited{
    color: #ccc;
}

.header__log-reg-out:hover{
    color: #fff;
    
}
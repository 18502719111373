.registration__entry {
  font-size: 14px;
  line-height: 1.21;
  font-weight: 400;
  color: #fff;
  margin-left: 4px;
  text-decoration: none;
  transition: 0.5s;
}

.registration__entry:visited {
  color: #fff;
}

.registration__entry:hover {
    border-bottom: 1px solid;

}

.popup__button-save {
  width: 358px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 37px;
  margin-top: 48px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.21;
  transition: 0.3s;
}

.popup__button-save:hover {
  cursor: pointer;
  background-color:rgba(0, 0, 0, 0.8);
  color: #fff;
}

.popup__button-save:focus {
  outline: none;
}

@media screen and (max-width: 742px) {
  .popup__button-save {
    width: 238px;
    height: 46px;
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 14px;
  }
}

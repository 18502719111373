.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 880px;
  width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  margin: 0 auto;
}

@media screen and (max-width: 742px) {
  .profile {
    flex-direction: column;
    padding-top: 42px;
    padding-bottom: 39px;
  }
}

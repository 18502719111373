.popup__window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 430px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 742px) {
  .popup__window {
    width: 282px;
  }
}

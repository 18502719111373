.profile__status {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.21;
  padding-top: 7px;
  margin: 0 0 0 30px;
  max-width: 305px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
}

@media screen and (max-width: 742px) {
  .profile__status {
    font-size: 14px;
    padding-top: 9px;
    margin: 0;
    max-width: 282px;
  }
}

.login__input {
  background-color: #000;
  border-right: none;
  border-left: none;
  border-top: none;
  width: 100%;
  color: #ccc;
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  border-bottom-width: 2px;
  color: #fff;
}

.login__input::placeholder {
  font-size: 14px;
  color: #ccc;
}

.login__input:focus {
  outline: none;
}

.login__input:enabled {
  padding-bottom: 4px;
}

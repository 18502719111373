.profile__edit-button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid #fff;
  background-image: url(./../../../images/pencil.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  transition: 0.3s;
}

.profile__edit-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.profile__edit-button:focus {
  outline: none;
}

@media screen and (max-width: 742px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    margin-right: 19px;
    margin-left: 11px;
  }
}

.profile__icon-edit {
  background-image: url(./../../../images/pencil.svg);
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  transition: 0.3s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
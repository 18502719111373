.popup__title {
  color: #000;
  margin: 34px 0 0 36px;
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
  line-height: 1.21;
}

@media screen and (max-width: 742px) {
  .popup__title {
    font-size: 18px;
    margin: 25px 22px 0px;
  }
}
